// 要素
const newsElm = document.getElementById('topNews');



// Initialize
document.addEventListener('DOMContentLoaded', function() {
  const requestUrl = 'https://kotobuki-srv.net/e-kawaguchi/news/index.php'; // server test
  //const requestUrl = 'http://localhost:10010/e-kawaguchi/news/index.php'; // Local

  $.post( requestUrl, function( data ){
    newsElm.innerHTML = data;
  });

});
